<template>
  <div class="app-box">
    <div class="headerBox">
      <div class="setBox">
        <h4 class="titleH4">个人信息</h4>
        <van-form @submit="onSubmit">
          <van-field
            v-model="formData.role"
            input-align="right"
            name="角色"
            label="角色"
            right-icon="arrow-down"
            readonly
            :rules="[{ required: true, message: '请选择角色' }]"
            @click="show = true"
          />
          <van-field
            v-model="formData.commission"
            input-align="right"
            type="text"
            name="佣金比例"
            label="佣金比例"
            right-icon="arrow-down"
            readonly
            :rules="[{ required: true, message: '请选择佣金比例' }]"
            @click="show1 = true"
          />
          <van-field
            v-model="formData.Wxname"
            input-align="right"
            type="text"
            name="微信昵称"
            label="微信昵称"
            readonly
          />
          <van-field
            v-model="formData.phone"
            input-align="right"
            type="text"
            name="手机号码"
            label="手机号码"
            readonly
          />
          <van-field
            v-model="formData.joining_date"
            input-align="right"
            type="text"
            name="加入时间"
            label="加入时间"
            readonly
          />
          <div class="borHex"></div>
          <h4 class="titleH4">个人状态</h4>
          <van-field
            v-model="formData.state"
            input-align="right"
            name="状态"
            label="状态"
            right-icon="arrow-down"
            readonly
            @click="show2 = true"
          />
          <div class="boepra">
            <van-button block type="info" native-type="submit" size="normal"
              >确认</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <!-- end弹窗角色 -->
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        value-key="name"
      />
    </van-popup>
    <!-- 佣金比例 -->
    <van-popup v-model="show1" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns1"
        @confirm="onConfirm1"
        @cancel="onCancel1"
        value-key="name"
      />
    </van-popup>
    <!-- 状态 -->
    <van-popup v-model="show2" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns2"
        @confirm="onConfirm2"
        @cancel="onCancel2"
        value-key="name"
      />
    </van-popup>
  </div>
</template>

<script>
import { operate, getMemberInfo } from "@/request/api";
import { Toast } from 'vant';
export default {
  data() {
    return {
      show: false,
      id: "",
      show1: false,
      show2: false,
      formData: {
        role: "",
        roleId: "",
        commission: "",
        commissionId: "",
        Wxname: "",
        phone: "",
        joinTime: "",
        state: "",
        typeId: "",
      },
      formti: {
        role: "",
        share_ratio: "",
        state: "",
        id:this.$route.query.id
      },
      columns: [
        { name: "总监", id: '4' },
        { name: "经理", id: '5' },
      ],
      columns1: [
        { name: "0%", id: '0' },
        { name: "5%", id: '5' },
        { name: "10%", id: '10' },
        { name: "15%", id: '15' },
        { name: "20%", id: '20' },
        { name: "25%", id: '25' },
        { name: "30%", id: '30' },
      ],
      columns2: [
        { name: "启用", id: '1' },
        { name: "禁用", id: '2' },
      ],
      typeShow: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        id:this.$route.query.id,
      };
      getMemberInfo(data).then((res) => {
        this.formData.joinTime = res.data.create_time;//加入时间
        this.formData.phone  = res.data.phone;//手机号码
        let typedata = this.columns2.filter(item => item.id == res.data.state);//状态
        this.formData.role =  res.data.role_name;//角色
        this.formti.role = res.data.role//角色
        this.formData.commission = res.data.share_ratio == null?'0':res.data.share_ratio + '%';//佣金比例
        this.formti.share_ratio = res.data.share_ratio;
        this.formti.state = typedata[0].id;//状态
        this.formData.state = typedata[0].name;//状态
        this.formData.Wxname = res.data.nickname//微信昵称
        
      });
    },
    gotfh() {
      this.$router.go(-1);
    },
    onSubmit() {
      // console.log(this.formti)
      operate(this.formti).then(res=>{
        if(res.code != 1000){
          Toast.fail(res.message)
          return
        }
        Toast('修改成功')
      })
    },
    onConfirm(value) {
      // console.log(value);
      this.formData.role = value.name;
      this.formti.role = value.id;
      this.show = false;
    },
    onConfirm1(value) {
      // console.log(value);
      this.formData.commission = value.name;
      this.formti.share_ratio = value.id;
      this.show1 = false;
    },
     onConfirm2(value) {
      // console.log(value);
      this.formData.state = value.name;
      this.formti.state = value.id;
      this.show2 = false;
    },
    onCancel() {
      this.show = false;
    },
    onCancel1() {
      this.show1 = false;
    },
    onCancel2() {
      this.show2 = false;
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
}
.headerBox {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
}
.setBox {
  border-bottom: 5px solid #edf2f5ff;
}
.setBox .titleH4 {
  line-height: 40px;
  padding: 5px 15px;
  box-sizing: border-box;
}
.titleH4::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 20px;
  margin-right: 5px;
  background: #0e8affff;
  vertical-align: middle;
  border-radius: 2px;
}
::v-deep .van-cell__title {
  color: #000;
}
.borHex {
  width: 100%;
  height: 7px;
  background: #edf2f5ff;
}
.boepra ::v-deep .van-button {
  border-radius: 10px;
  margin: auto;
  width: 50%;
}
.boepra {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}
::v-deep .van-nav-bar .van-icon {
		color: #000;
	}
</style>